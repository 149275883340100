import React, { useEffect } from 'react';
import { withAssetPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useIntl, Link } from 'gatsby-plugin-intl';
import DrawerButton from './Drawer';
import SelecaoIdioma from './SelecaoIdioma';
import MenuNav from './menuNav';
import LogoIdeia from '../images/logo-ideia-natal.png';
import configJson from '../data/config.json';

export default ({ location }) => {
  const intl = useIntl();

  useEffect(() => {
    const nav = document.querySelector('.menufixo');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 150 && window.innerWidth > 990) {
        nav.classList.add('fixo');
        document.body.style.paddingTop = '70px';
      } else {
        nav.classList.remove('fixo');
        document.body.style.paddingTop = 0;
      }
    });
  }, []);

  return (
    <header>
      <Helmet>
        <link rel="apple-touch-icon-precomposed" href={withAssetPrefix('images/icones-ideia/144x144.png')} />

        {/* To support old sizes */}
        <link rel="apple-touch-icon-precomposed" href={withAssetPrefix('images/icones-ideia/57x57.png')} />
        <link rel="apple-touch-icon-precomposed" href={withAssetPrefix('images/icones-ideia/72x72.png')} />
        <link rel="apple-touch-icon-precomposed" href={withAssetPrefix('images/icones-ideia/114x144.png')} />
      </Helmet>

      <DrawerButton />
      <div className="barraCinza pt-2 pb-1">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-12 enderecos" style={{ paddingRight: 10 }}>
              <i className="fas fa-phone mr-10"></i>{intl.formatMessage({ id: 'Fone' })}: <a style={{ color: '#ffff' }} href={`tel:${configJson.telefoneUrl}`}>{configJson.telefone}</a>
              <i className="fas fa-map-marker mr-10 ml-10"></i>
              <a target="_blank" rel="noreferrer" style={{ color: '#ffff' }} href={configJson.enderecoUrl}>{intl.formatMessage({ id: 'Rua' })} Amazonas, 363 - 09520-070 - São Caetano do Sul / SP - {intl.formatMessage({ id: 'Brasil' })}</a>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-12">
              <div className="row">
                <div className="col-xl-8 col-lg-7 col-md-12 redesSociais text-right">
                  <a target="_blank" rel="noreferrer" title="Face Ideia 2001" aria-label="Facebook Ideia 2001" href={configJson.facebook}>
                    <i className="fab fa-facebook-square fa-xs ml-10"></i>
                  </a>
                  <a target="_blank" rel="noreferrer" title="Instagram Ideia 2001" aria-label="Instagram Ideia 2001" href={configJson.instagram}>
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a target="_blank" rel="noreferrer" title="Linkedin Ideia 2001" aria-label="Linkedin Ideia 2001" href={configJson.linkedin}>
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>

                <SelecaoIdioma />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="menufixo">
        <div className="container mt-40">
          <div className="row">
            <div className="col-xl-3 col-lg-2 col-md-12 logo">
              <Link to="/" title={intl.formatMessage({ id: 'Ideia 2001 Catálogos Inteligentes' })}>
                <img src={LogoIdeia} alt={intl.formatMessage({ id: 'Ideia 2001 - Catálogos Inteligentes' })} />
              </Link>
            </div>
            <div className="col-xl-9 col-lg-10 col-md-9">
              <div className="row">
                <nav id="site-navigation" className="main-navigation col-lg-9 col-md-12">
                  <MenuNav location={location} />
                </nav>
                <div className="col-lg-3 col-md-3">
                  <Link to="/orcamento" className="tenhoInteresse">{intl.formatMessage({ id: 'Tenho interesse' })}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};
